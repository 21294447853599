import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "antd/dist/antd.css";
import * as dotenv from "dotenv";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import NotFound from './components/NotFound'
import Home from './components/Home'
dotenv.config();
ReactDOM.render(
    <BrowserRouter>
      <Switch>
        <Route path={`/:slug`} component = {App}/>
       
        <Route path='/' exact component = {Home}/>
        <Route component={NotFound} />
      </Switch>
    </BrowserRouter>
,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
