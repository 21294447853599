import * as React from 'react'
import { Card, Button, Input, Typography, DatePicker, Upload } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import _ from 'lodash'
import useWindowDimensions from './../hooks/useWindowDimensions.js'
import Axios from 'axios'
interface IAnwserQuestion {
  answerValue?: string
  answerLabel?: string
}

interface IQuestionField {
  fieldLabel: string
  fieldTitle?: string
  fieldType: string
  fieldGroup?: string
  _id: string
  answerOptions?: IAnwserQuestion[]
  requireAnswer?: boolean
}
interface IProps {
  fieldData: IQuestionField
  order: number
  [key: string]: any
  cbAnswer?: Function
  cbInfo?: Function
}

const FieldItem: React.SFC<IProps> = ({ fieldData, order, cbAnswer }) => {
  const answerChange = (answers: Object) => {
    if (cbAnswer) {
      cbAnswer(order, {
        fieldId: fieldData._id,
        fieldLabel: fieldData.fieldLabel,
        fieldGroup: fieldData.fieldGroup,
        fieldType: fieldData.fieldType,
        requireAnswer: fieldData.requireAnswer,
        answers,
      })
    }
  }

  const [indexSelected, setIndexSelected] = React.useState<Number>()
  const { width } = useWindowDimensions()

  const generateAnswer = (
    answerOptions: IAnwserQuestion[] | undefined,
    type: string
  ) => {
    switch (type) {
      case 'scale':
        return (
          <>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-around',
                flexWrap: 'wrap',
              }}
            >
              {answerOptions?.map((answer, index: number) => {
                const typeBtn = indexSelected === index ? 'primary' : 'default'

                const sizeBtn: any = width > 600 ? 'normal' : 'small'
                return (
                  <Button
                    key={fieldData._id + '_' + answer.answerValue}
                    type={typeBtn}
                    size={sizeBtn}
                    style={{ marginTop: '3%' }}
                    onClick={() => {
                      answerChange([
                        {
                          answerLabel: answer.answerLabel,
                          answerValue: answer.answerValue,
                        },
                      ])

                      if (index === indexSelected) {
                        setIndexSelected(-1)
                      } else {
                        setIndexSelected(index)
                      }
                    }}
                  >
                    <p>{answer.answerLabel}</p>
                  </Button>
                )
              })}
            </div>
          </>
        )
      case 'date':
        return (
          <>
            <DatePicker
              style={{ width: '100% ' }}
              placeholder="Vui lòng chọn ngày"
              onChange={(selectTime) => {
                answerChange([
                  {
                    answerLabel: fieldData?.fieldTitle,
                    answerValue: selectTime,
                  },
                ])
              }}
            />
          </>
        )
      case 'textarea':
        return (
          <Input.TextArea
            placeholder="Nhập câu trả lời của bạn"
            rows={3}
            required
            onChange={(e) =>
              answerChange([
                {
                  answerLabel: '',
                  answerValue: e.target.value,
                },
              ])
            }
          />
        )
      case 'image':
        const getBase64 = (file: any, cb: any) => {
          let reader = new FileReader()
          reader.readAsDataURL(file)
          reader.onload = function () {
            cb(reader.result)
          }
          reader.onerror = function (error) {
            console.log('Error: ', error)
          }
        }

        const props = {
          action: `${process.env.REACT_APP_SERVER_URL}/form-entries/uploadImage`,
          name: 'images',
          onChange: ({ fileList }: any) => {
            let answers: any = []
            _.forEach(fileList, (file) => {
              const imageLink = _.get(file, 'response.0.link')
              if (imageLink) {
                answers.push({
                  answerLabel: '',
                  answerValue: imageLink,
                })
              }
            })
            answerChange(answers)
          },
          customRequest: ({ onSuccess, onError, file, action }: any): any => {
            getBase64(file, (data: any) => {
              Axios.post(action, { images: [{ data }] })
                .then((res) => {
                  onSuccess(res.data)
                })
                .catch((err) => {
                  onError()
                })
            })
          },
        }
        return (
          <>
            <Upload {...props} listType="picture">
              <Button>
                <UploadOutlined /> Upload
              </Button>
            </Upload>
          </>
        )
      default:
        return (
          <Input
            placeholder="Nhập câu trả lời của bạn"
            type="text"
            required
            onChange={(e) =>
              answerChange([
                {
                  answerLabel: '',
                  answerValue: e.target.value,
                },
              ])
            }
          />
        )
    }
  }
  return (
    <>
      {fieldData?.fieldType === 'divider' ? (
        <>
          {' '}
          <Typography.Title style={{ marginTop: 30, marginLeft: 6 }} level={3}>
            {fieldData?.fieldTitle}:{' '}
          </Typography.Title>{' '}
        </>
      ) : (
        <Card style={{ borderRadius: 10, marginTop: 20 }}>
          <h3>
            <b>
              <b>{fieldData.fieldTitle}</b> {fieldData.fieldLabel}{' '}
              {fieldData.requireAnswer ? (
                <span style={{ color: 'red', fontSize: '25px' }}>*</span>
              ) : (
                ''
              )}
            </b>
          </h3>
          <div className="answear">
            {generateAnswer(fieldData?.answerOptions, fieldData.fieldType)}
          </div>
        </Card>
      )}
    </>
  )
}
export default FieldItem
