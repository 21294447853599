import React, { useEffect, useState } from 'react'
import FieldItem from './components/FieldItem'
import { Button, Divider, Result, Row, Col } from 'antd'
import { useParams, useHistory } from 'react-router-dom'
import { IData } from './types/types'
import axios from 'axios'
import {
  get,
  countBy,
  filter,
  forEach,
  reduce,
  map,
  difference,
  differenceBy,
} from 'lodash'
const agent = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL,
})

const AnswerComponent: React.FC = () => {
  const { slug } = useParams()
  let history = useHistory()
  useEffect(() => {
    const backgroundColor =
      slug === 'trac-nghiem-tinh-cach' ? '#ffa8a9' : '#54a0ff'
    document.body.style.backgroundColor = backgroundColor

    const getQuestions = async () => {
      try {
        const { data } = await agent.get('/work-forms/' + slug)
        document.title = data.title
        setData(data)
      } catch (error) {
        history.push('/')
      }
    }
    getQuestions()
  }, [slug])

  const [data, setData] = useState<IData>()
  const [isSending, setSending] = useState(false)
  const [isDone, setIsDone] = useState(false)
  const [sendFields, setSendFields] = useState<{ [key: string]: any }[]>([])

  const onCbFields = (order: string, answerData: any) => {
    let index = sendFields.findIndex((field: any) => {
      return field.fieldId === answerData.fieldId
    })

    if (index === -1) {
      sendFields.push(answerData)
    } else {
      sendFields[index] = answerData
    }
    setSendFields(sendFields)
  }

  const onSubmitData = async () => {
    const answerData: any = {
      formId: data?._id,
      formTitle: data?.title,
      fields: [...sendFields],
    }

    const questionRequireAnswers = filter(data?.fields, (field) => {
      return field.requireAnswer === true
    })

    const answeredQuestionRequire = filter(answerData.fields, {
      requireAnswer: true,
    })

    const leftRequiredQuestions = differenceBy(
      questionRequireAnswers,
      answeredQuestionRequire,
      'fieldLabel'
    )
    const notifyContent = reduce(
      leftRequiredQuestions,
      (result, leftRequiredQuestion) => {
        result += `${leftRequiredQuestion.fieldGroup} - ${leftRequiredQuestion.fieldTitle}\n`
        return result
      },
      'Bạn còn các câu sau chưa trả lời:\n'
    )

    if (leftRequiredQuestions.length) {
      return window.alert(notifyContent)
    }

    setSending(true)
    agent
      .post('form-entries', answerData)
      .then((res) => {
        setIsDone(true)
      })
      .catch((err) => {
        alert(get(err, 'response.data.message', 'Lỗi không xác định'))
      })
      .finally(() => {
        setSending(false)
      })
  }

  return (
    <Row justify="center">
      <Col span={18}>
        {isDone ? (
          <Result
            status="success"
            title="Chúc mừng bạn đã hoàn thành form"
            subTitle="Dữ liệu đã được gửi đi. Bạn sẽ nhận được kết quả sớm nhất!"
            extra={[
              <Button
                onClick={() => {
                  window.location.reload()
                }}
                key={1}
              >
                Làm lại
              </Button>,
            ]}
          />
        ) : (
          <>
            <>
              <div style={{ textAlign: 'center' }}>
                <h1 style={{ fontSize: 45, marginTop: 20 }}>{data?.title}</h1>

                <span>{data?.description}</span>
              </div>
              <Divider dashed />
              {data?.fields.map((field, index) => {
                return (
                  <FieldItem
                    fieldData={field}
                    key={field._id}
                    order={index}
                    cbAnswer={onCbFields}
                  />
                )
              })}
              <Divider dashed />
              <Button
                type="primary"
                disabled={isSending}
                size="large"
                onClick={() => onSubmitData()}
                style={{ marginBottom: 50 }}
              >
                Gửi
              </Button>
            </>
          </>
        )}
      </Col>
    </Row>
  )
}
export default AnswerComponent
