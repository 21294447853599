import React from "react";

import { Result } from "antd";


const Home: React.SFC = () => {
  
  return (
    <Result
        status="403"
        title="4handy Work Form"
       
    />
  );
};
export default Home;
